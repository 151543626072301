import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '40, 7, 77',
		'primary-dark': '23, 4, 44',
		'accent': '242, 54, 41',
		'accent-plus': '255, 255, 255',
	},
});
